@import "../../scss/components/button";
@import "../../scss/utilities/variables";
@import "../../scss/utilities/mixins";
@import "../../scss/utilities/keyframes";

.container {
    @include flexbox;
    @include box-decoration;
    gap: 2rem;
    background-color: $night-grey;
    border-radius: 15px;
    box-shadow: 3px 3px 6px #080808,
        -1px -1px 3px #222222;
    max-width: 90rem;
    padding: 2rem;
    position: relative;
    width: 90%;
}

.form {
    color: $white;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
    gap: 4rem 2rem;
    font-size: 1.6rem;
    max-width: 80rem;
    padding: 1rem;
    position: relative;
    width: 100%;
    z-index: 10;

    button {
        font-size: 1.6rem;
        margin: 1rem auto;
        max-width: 40rem;
        width: 100%;

        @include for-medium-screens {
            max-width: 30rem;
        }
    }

    &Group {
        max-width: 40rem;
        width: 100%;

        @include for-medium-screens {
            width: 45%;
        }

        label {
            position: absolute;
            top: -1000000000rem;
            left: -100000000rem;
            height: 1px;
            width: 1px;

        }

        input {
            border: 1px solid transparent;
            border-radius: 5px;
            background: linear-gradient(145deg, #131313, #161616);
            box-shadow: 7px 7px 13px #080808,
                -7px -7px 13px #222222;
            color: $white;
            font-size: 1.6rem;
            outline: none;
            padding: 1.5rem;
            transition: all .5s;
            width: 100%;

            &::placeholder {
                color: $regular-grey;
            }

            &:focus {
                border-color: $dark-grey;
            }
        }

        textarea {
            border: 1px solid transparent;
            background-color: $night-grey;
            border-radius: 5px;
            background: linear-gradient(145deg, #131313, #161616);
            box-shadow: 7px 7px 13px #080808,
                -7px -7px 13px #222222;
            color: $white;
            font-family: $font-family-base;
            font-size: 1.6rem;
            outline: none;
            padding: 2rem;
            transition: all .5s;
            resize: none;
            width: 100%;

            &::placeholder {
                color: $regular-grey;
            }


            &:focus {
                border-color: $dark-grey;
            }
        }

        &:first-child {
            @include flexbox($flow:row wrap, $a:flex-start, $j:space-between);
            gap: 5.5rem;
        }

    }
}