@import "../utilities/mixins";
@import "../utilities/variables";
.button {
    @include box-decoration;
    background-color: $night-grey;
    background: linear-gradient(145deg, #101010, #161616);
    border-radius: 5px;
    color: $white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    padding: 1.5rem 4rem;
    transition: all .5s;
    text-decoration: none;
    
    &:hover{
        // background: linear-gradient(145deg, #101010, #181818);
        border-color: #202020;
        transform: scale(1.05);
    }
    &:active{
        // background: linear-gradient(145deg, #080808, #161616);
        border-color: #101010;
        transform: scale(1);
    }
}