@mixin border($c:magenta) {
    border: 1px solid $c;
}

// Box decoration
@mixin box-decoration() {
    box-shadow: 3px 3px 6px #080808, -1px -1px 0 #222;
    border: 1px solid #101010;
}


//  Flexbox
@mixin flexbox($flow: column nowrap, $j: center, $a: center) {
    display: flex;
    flex-flow: $flow;
    justify-content: $j;
    align-items: $a;
}

// Horizontal center
@mixin h-center {
    margin-left: auto;
    margin-right: auto;
}

// Media queries
@mixin for-medium-screens {
    @media screen and (min-width: 768px) {
        @content;
    }
}

@mixin for-large-screens {
    @media screen and (min-width: 1024px) {
        @content;
    }
}