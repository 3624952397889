@import "../../scss/utilities/variables";
@import "../../scss/utilities/keyframes";
@import "../../scss/utilities/mixins";

.container {
    @include flexbox;
    padding: 1rem;
    position: absolute;
    top: 2.5rem;
    left: 0;
    width: 100%;
    z-index: 500;

    @include for-medium-screens {
        top: 4rem;
    }

    @include for-large-screens {
        top: unset;
        bottom: 3rem;
    }

}

.links {
    // @include border;
    @include flexbox($flow:row nowrap);
    gap: 2rem;
    font-size: 3.5rem;
    position: relative;

    a {
        color: $white;
        display: inline-block;
        text-decoration: none;
        transition: all .5s;

        &:hover {
            color: $light-green;
        }

        &:active {
            color: $regular-green;
        }
    }

    &::after,
    &::before {
        animation: moveGradient 10s ease alternate infinite;
        content: "";
        position: absolute;
        top: 50%;
        width: 100%;
        height: 2px;
        background: $gradient-4;
        background-size: 400%;
        background-position: 0 50%;    }

    &::after {
        left: -120%;
    }

    &::before {
        right: -120%;
    }

    @include for-medium-screens {

        &::after,
        &::before {
            width: 20rem;
        }

        &::after {
            left: -260%;
        }

        &::before {
            right: -260%;
        }
    }
}