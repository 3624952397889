@import "../../scss/utilities/variables";
@import "../../scss/utilities/mixins";

.container {
    @include box-decoration;
    background: linear-gradient(145deg, #1d1d1d, #222222);
    // border: 1px solid rgb(37, 37, 37);
    border-radius: 10px;
    width: 95%;
    margin: auto;
    max-width: 35rem;
    transition: all 0.5s;
}

.image {
    border-radius: 10px 10px 0rem 0rem;
    height: 27rem;
    object-fit: cover;
    object-position: center;
    width: 100%;
}

.title {
    padding: 2rem;
    text-align: center;

    font: {
        size: 2rem;
        weight: 500;
    }
}

.info {
    // @include border;
    @include flexbox($a:flex-start);
    gap: 2rem;
    margin: 1rem auto;
    padding-left: 3rem;
    font-size: 1.6rem;
    text-align: center;


    span {
        color: $light-green;
        display: inline-block;
        margin: 0 1rem 0 .5rem;
    }
}

.buttons {
    @include flexbox($flow: row wrap, $j:space-evenly);
    // @include flexbox;
    row-gap: 2rem;
    margin: 3rem auto;
    width: 100%;


    svg {
        color: $white;
        fill: $white;
        font-size: 1.6rem;
    }

    path {
        stroke: $white;
    }

    a {
        background: #151515;
        border-bottom: 1px solid #101010;
        box-shadow: 0 5px 5px #000000a9;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .5rem;
        font-size: 1.6rem;
        transition: all .4s;
        text-align: center;
        padding: 1.5rem 1rem;
        width: 50%;

        &:hover {
            background: #202020;
        }

        &:active {
            background: #101010;
        }
    }
}