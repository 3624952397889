@import "../utilities/mixins";

.container {
    @include flexbox;
    padding: 6rem 0 8rem;
    overflow: hidden;
    width: 100%;

    @include for-large-screens {
        padding: 8rem 0 6rem;
    }
}