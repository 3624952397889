@import "../../scss/utilities/mixins";

.container {
    @include flexbox;
    min-height: 100vh;
    overflow-y: hidden;
    position: relative;
    width: 100%;
}

.content {
    @include flexbox;
    margin: 9rem auto;
    max-width: 1400px;
    width: 90%;

    @include for-large-screens {
        margin: 5rem auto 0;
    }
}