@import "../../scss/utilities/variables";
@import "../../scss/utilities/mixins";

.container {
    max-width: 35rem;
    width: 100%;
}

.content {
    @include flexbox($flow:row nowrap);
    gap: 3rem;
    background-color: #181818;
    background: $gradient-6;
    border: #121212 solid 2px;
    border-radius: 10px;
    box-shadow: 2px 2px 3px #08080886;
    color: $white;
    cursor: pointer;
    height: 15rem;
    text-decoration: none;
    padding: 0 5rem;
    position: relative;
    transition: all .5s;
    transform: none;

    &:hover {
        background-color: #151515;
        transform: scale(1.03);

        svg {
            color: $light-green;
        }
    }

    &:active {
        transform: scale(.98) !important;
        border-color: $gsap-green;
    }

    svg {
        color: $light-green;
        font-size: 10rem;
        transition: color .5s;

        @include for-large-screens {
            color: $dark-green;

        }
    }

}

.title {
    color: $white;
    font-size: 1.7rem;
}