.swiper {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 50px;
}

.swiper-slide {
    margin: auto;
    /* background-position: center; */
    /* background-size: cover; */
    /* width: 100px; */
    /* height: 100px; */
}

/* .swiper-slide img {
    display: block;
    width: 100%;
  }
   */

.swiper-button-prev,
.swiper-button-next {
    color: #12CDA8 !important;
}

.swiper-pagination-bullet-active {
    background: #12CDA8 !important;
}