@import "../../scss/utilities/variables";
@import "../../scss/utilities/mixins";

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 40rem;
  height: 100vh;
  z-index: 1000000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  transition: all 0.5s;
}

.alertContainer {
  @include box-decoration;
  background: linear-gradient(145deg, #1d1d1d, #222222);
  border-radius: 10px;
  width: 90%;
  margin: auto;
  max-width: 40rem;
  padding: 5rem;
  transition: all 0.5s;
  position: relative;
}

.closeAlertButton {
  position: absolute;
  top: 5rem;
  right: 2rem;
  font-size: 2.5rem;
  transition: all 0.3s;
  color: $white;

  &:hover {
    cursor: pointer;
    color: $regular-grey;
  }

  &:active {
    color: $white;
  }
}

.title {
  font: {
    size: 2.5rem;
    weight: 700;
  }
  margin-bottom: 2.5rem;
}

.content {
  font-size: 1.6rem;
}
