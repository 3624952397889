@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&family=Raleway:wght@200;300;400;500;600;700&display=swap');

// Font Family
$font-family-base: 'Raleway',
sans-serif;
$font-family-base-heading: 'Alex Brush',
cursive;

// Font weight
$extra-light-font-weight: 200;
$light-font-weight: 300;
$regular-font-weight: 400;
$medium-font-weight: 500;
$semi-bold-font-weight: 600;
$bold-font-weight: 700;

// Green colors
$light-green :#12CDA8;
$medium-green :#15B897;
$regular-green : #18856F;
$dark-green : #0A735E;
$night-green: #074539;
$gsap-green: #12967b;

//  B-W colors
$white : #FFFFFF;
$light-grey : #eeeeee;
$medium-grey : #dddddd;
$regular-grey : #9f9f9f;
$dark-grey : #525252;
$night-grey: #151515;
$black : #000000;

// Gradients
$gradient-1 : linear-gradient(to right, #0A735E, #074539);
$gradient-2 : linear-gradient(to left, #074539, #000000);
$gradient-3 : linear-gradient(to left, #074539be, #000000e3);
$gradient-4 : linear-gradient(to right,
    #15977d,
    #1bdfb8,
    #55f0d1,
    #189178,
    #16b192,
);
$gradient-5 : linear-gradient(to right,
    #fff,
    #ccc,
    #bbb,
    rgb(255, 255, 255),
    #999,
);

$gradient-6: linear-gradient(145deg, #101010, #161616)