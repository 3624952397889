@import "../../scss/utilities/variables";
@import "../../scss/utilities/mixins";
@import "../../scss/utilities/keyframes";

.container {
    @include flexbox;
    background: url("../../assets/images/home.webp") no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 30rem;
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.content {
    @include flexbox;
    margin-bottom: 3rem;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 10;
}

.logo {
    width: 5rem;

    @include for-medium-screens {
        width: 5.5rem;
    }
}

.title {
    font-family: $font-family-base-heading;
    font-size: 5.2rem;
    font-weight: 500;
    text-shadow: 1px 0px 1px $night-green;
    margin: 1.5rem 0 .5rem;

    @include for-medium-screens {
        text-shadow: 1px 0px 2px $night-green;
        font-size: 9rem;
        margin: 2rem 0 -1rem;
    }

    @include for-large-screens {
        font-size: 10rem;

    }
}

.subTitle {
    animation: moveGradient 4s ease alternate infinite;
    background: $gradient-5;
    background-size: 600%;
    background-position: 0 50%;
    background-clip: text;
    -webkit-background-clip: text;
    -ms-background-clip: text;
    color: transparent;
    font-size: 2.5rem;
    font-weight: $light-font-weight;

    strong {
        animation: moveGradient 4s ease alternate infinite;
        background: $gradient-4;
        background-size: 600%;
        background-position: 0 50%;
        background-clip: text;
        -webkit-background-clip: text;
        -ms-background-clip: text;
        color: transparent;
        font-weight: $light-font-weight;    
    }

    @include for-medium-screens {
        font-size: 4rem;
    }
}