@import "../../scss/utilities/variables";
@import "../../scss/utilities/mixins";

.container {
    @include flexbox;
    background-color: #151515;
    background: $gradient-6;
    border-top: 3px solid #252525;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1000000;

    @include for-large-screens {
        border-bottom: 3px solid #252525;
        padding: 0;
        top: 0;
        bottom: unset;
    }
}

.nav {
    @include flexbox($flow:row nowrap);
    list-style-type: none;

    &Link {
        @include flexbox;
        border-bottom: 3px solid transparent;
        border-top: 3px solid transparent;
        color: $white;
        font-size: 2.6rem;
        text-decoration: none;
        transition: all 0.3s;
        padding: 1.2rem 2rem .9rem;

        &:hover {
            background-color: rgba(80, 80, 80, 0.314);
        }

        &[aria-current="page"] {
            color: lighten($light-green, 10%);
            border-top-color: $light-green;
            background-color: rgba(64, 64, 64, 0.314);
        }

        span {
            display: none;
        }

        @include for-medium-screens {
            padding: .9rem 2.5rem 1.2rem;
        }

        @include for-large-screens {
            padding: .9rem 2.5rem 1.2rem;

            &[aria-current="page"] {
                border-top-color: transparent;
                border-bottom-color: $light-green;
            }
        }
    }
}