@import "../../scss/components/container";
@import "../../scss/utilities/variables";
@import "../../scss/utilities/mixins";

.container {
    // @include border;
    margin: 3rem auto;
}

.content {
    // @include border($c: cyan);
    // @include flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    gap: 4rem;
    margin: 2rem auto;
    max-width: 105rem;
    width: 90%;

    @include for-large-screens {
        margin: 5rem auto 0;
    }
    // @media screen and (min-width: 1300px) {
    //     padding: 3rem 5rem;
    // }
}