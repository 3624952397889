@import "../../scss/utilities/variables";
@import "../../scss/utilities/mixins";
@import "../../scss/utilities/keyframes";

.container {
    @include flexbox;
    overflow: visible;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 10;

    img {
        width: 4rem;
    }

    h2 {
        font-family: $font-family-base-heading;
        font-size: 4rem;
        font-weight: 500;
        margin: 2rem 0 0;
        text-shadow: 1px 0px 2px $night-green;

        @include for-medium-screens {
            font-size: 6rem;
        }
    }

    h3 {
        animation: moveGradient 5s ease alternate infinite;
        background: $gradient-5;
        background-size: 400%;
        background-position: 0 50%;
        background-clip: text;
        -webkit-background-clip: text;
        -ms-background-clip: text;
        color: transparent;
        font-size: 2.5rem;
        font-weight: $regular-font-weight;

        strong {
            animation: moveGradient 5s ease alternate infinite;
            background: $gradient-4;
            background-size: 400%;
            background-position: 0 50%;
            background-clip: text;
            -webkit-background-clip: text;
            -ms-background-clip: text;
            color: transparent;
            font-size: 2.5rem;
            font-weight: $regular-font-weight;    
        }
    }
}

.nav {
    @include flexbox($flow:row nowrap);
    gap: 2rem;
    font-size: 3rem;
    margin: 1rem auto;
    position: relative;
    width: 10rem;
    z-index: 10;

    a {
        color: $white;
        display: inline-block;
        text-decoration: none;
        transition: all .5s;

        &:hover {
            color: $light-green;
        }

        &:active {
            color: $regular-green;
        }
    }

    &::after,
    &::before {
        content: "";
        position: absolute;
        top: 50%;
        width: 85%;
        height: 2px;
        background-color: $light-green;

        @media screen and(min-width:500px) {
            width: 150%;
        }
    }

    &::after {
        left: -85%;
    }

    &::before {
        right: -85%;
    }

    @media screen and(min-width:500px) {

        &::after {
            left: -150%;
        }

        &::before {
            right: -150%;
        }
    }
}