@import "../../scss/utilities/variables";
@import "../../scss/utilities/keyframes";
@import "../../scss/utilities/mixins";

.container {
    @include box-decoration;
    @include flexbox;
    background-color: $night-grey;
    border-radius: 5px;
    margin: auto;
    padding: 5rem 3rem;
    width: 100%;

    @include for-medium-screens {
        gap: 2rem;
    }

    @include for-large-screens {
        flex-direction: row;
        padding: 2rem 3rem;
    }
    @media screen and (min-width: 1300px) {
        padding: 3rem 5rem;
    }
}

.picture {
    max-width: 50rem;
    width: 100%;

    img {
        width: 100%;
    }
}

.info {
    @include flexbox($a: flex-start);
    gap: 2rem;
    font-size: 1.6rem;
    padding: 1rem;
    max-width: 55rem;
    width: 100%;

    h2 {
        animation: moveGradient 4s ease alternate infinite;
        background: $gradient-4;
        background-size: 400%;
        background-position: 0 50%;
        -webkit-background-clip: text;
        -ms-background-clip: text;
        -o-background-clip: text;
        background-clip: text;
        color: transparent;
        font-size: 2.5rem;
        font-weight: $bold-font-weight;
        text-align: center;
        width: 100%;
    }

    p {
        line-height: 1.5;
    }
}

.techs {
    @include flexbox($flow: row wrap, $j:space-evenly, $a:flex-end);
    gap: 2rem;
    margin: 1rem auto;
    max-width: 30rem;
    width: 100%;

    h3 {
        width: 100%;
    }
}

.logo {
    @include flexbox;
    gap: 1rem;

    img {
        width: 60%;
    }

    span {
        font-size: 1.4rem;
    }
}

.buttons {
    @include flexbox($flow: row nowrap, $j:space-evenly);
    // @include border($c: rgb(187, 255, 0));
    // @include flexbox;
    gap: 2rem;
    margin: 1rem auto;
    width: 100%;


    svg {
        color: $white;
        fill: $white;
        font-size: 1.6rem;
    }

    path {
        stroke: $white;
    }

    a {
        background: $gradient-6;
        border-bottom: 1px solid #101010;
        box-shadow: 0 5px 5px #000000a9;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .5rem;
        font-size: 1.6rem;
        transition: all .4s;
        text-align: center;
        padding: 1.5rem 1rem;
        width: 50%;

        &:hover {
            background: #202020;
        }

        &:active {
            background: #101010;
        }
    }
}