@import "../../scss/utilities/mixins";
@import "../../scss/utilities/variables";

.container {
    // @include border;
    @include flexbox($j:flex-start);
    gap: 3rem;
    line-height: 23px;
    padding: 2rem 1rem;
    max-width: 25rem;
    width: 100%;
}

.header {
    // @include border;
    @include flexbox($j:flex-start, $a:flex-start);
    height: 14rem;
    text-align: left;
    width: 100%;

    h3 {
        margin-top: auto;
        font-weight: $regular-font-weight;
        width: 100%;
    }

    &Logo {
        margin: auto 0;
    }

    strong {
        color: $light-green;
        display: block;
        font-weight: $medium-font-weight;
        margin-top: .5rem;
    }
}