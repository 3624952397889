@import "../../scss/components/container";
@import "../../scss/components/button";
@import "../../scss/utilities/mixins";
@import "../../scss/utilities/variables";

body[data-aos-delay='4000'] [data-aos],
[data-aos][data-aos][data-aos-delay='4000'] {
    transition-delay: 4000ms !important;
}

.content {
    @include flexbox;
    @include box-decoration;
    gap: 4rem;
    background-color: $night-grey;
    border-radius: 10px;
    font-size: 1.5rem;
    margin: 2rem auto;
    max-width: 100rem;
    padding: 4rem 2rem;
    position: relative;
    width: 90%;

    @include for-medium-screens {
        gap: 6rem;
    }
}

.personal {
    @include flexbox($flow: row wrap, $j:space-evenly, );
    gap: 3rem;

    @include for-medium-screens {
        flex-wrap: nowrap;
    }

    &Image {
        @include box-decoration;
        border-radius: 5px;
        object-fit: cover;
        object-position: bottom;
        height: 40rem;
        max-width: 35rem;
        width: 100%;
        opacity: 0;
        order: -1;
        transition: opacity 0.5s ease;

        @include for-medium-screens {
            max-width: 30rem;
            order: 1;
            width: 40%;
        }

        &Loaded {
            opacity: 1;
        }
    }

    &Description {
        @include flexbox;
        gap: 2rem;
        line-height: 23px;
        max-width: 55rem;
        width: 100%;

        @include for-medium-screens {
            width: 60%;
        }

        a {

            svg {
                font-size: 2rem
            }
        }
    }

}

.education {
    @include flexbox;
    border-top: 1px solid $dark-grey;
    border-bottom: 1px solid $dark-grey;
    padding: 4rem 0;

}


.hobbies {
    @extend .education;
    border: none;
    padding: 2rem 0;

    .flexContainer {
        gap: 3rem;
        justify-content: space-evenly;
        margin: 2rem auto 3rem;
    }
}


.subTitle {
    border-bottom: $light-green 2px solid;
    font-size: 2.5rem;
    margin-bottom: 3rem;
    text-align: center;
}

.flexContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 3rem;
    width: 100%;

    @include for-large-screens {
        gap: 1rem;
        justify-content: space-between;
    }


}

.animationContainer {
    @include flexbox;
    gap: 4rem;
    width: 100%;

    section {
        max-width: 90rem;
        width: 90%;
    }

    @include for-medium-screens {
        gap: 6rem;
    }
}