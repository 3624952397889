@keyframes floating {
    to {
        transform: translateY(0);
    }
}

@keyframes beat {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.1);
    }
}

@keyframes moveGradient {
    50% {
        background-position: 100% 250%;
    }
}