@import "../../scss/utilities/mixins";
@import "../../scss/utilities/variables";
@import "../../scss/utilities/keyframes";

.container {
    @include box-decoration;
    @include flexbox;
    background-color: darken($night-grey, 1%);
    background: $gradient-6;
    border-radius: 5px;
    gap: 2rem;
    max-width: 25rem;
    padding: 2rem 3rem;
    text-align: center;
    width: 100%;

    h3 {

        font: {
            size: 1.5rem;
            weight: $regular-font-weight;
        }
    }

    svg {
        fill: $gradient-4;
        font-size: 4rem;
    }
}