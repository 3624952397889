@import "../../scss/utilities/variables";
@import "../../scss/utilities/mixins";

.container {
    @include flexbox;
    gap: 4rem;
    margin: 4rem auto;
    width: 100%;

    h2 {
        font-weight: $medium-font-weight;
        text-align: center;
    }

}

.title {
    color: $light-grey;
    font-size: 2rem;
}

.cardsContainer {
    @include flexbox($flow: row wrap, $a: center, $j: space-evenly);
    gap: 3rem;
    width: 100%;
}