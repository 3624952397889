@import "../../scss/utilities/variables";
@import "../../scss/utilities/mixins";
@import "../../scss/utilities/keyframes";

.notFoundPage {
    @include flexbox;
    padding: 3rem 0;
    min-height: 30rem;
    height: 100vh;

    &Illustration {
        // @include border;
        margin-top: 6rem;
        position: relative;
        max-width: 50rem;
        width: 90%;

        img:first-child {
            animation: floating 2s .5s infinite alternate;
            transform: translateY(-15%);
            position: absolute;
            top: -30%;
            left: 30%;
            width: 40%;
        }

        img:last-child {
            width: 100%;
        }

    }

    &Content {
        margin-top: 5rem;
        margin-bottom: 3rem;
        text-align: center;

        h1 {
            position: absolute;
            top: 5000000;
            left: 5000000;
            height: 1px;
            width: 1px;
            overflow: hidden;
        }

        a {
            background-color: #252525;
            border-radius: 5px;
            border: 1px solid #252525;
            box-shadow: 3px 3px 3px #101010;
            color: $white;
            display: block;
            font-size: 1.7rem;
            text-decoration: none;
            transition: all 0.3s ease-in-out;
            padding: 1.5rem 4rem;

            &:hover {
                box-shadow: 5px 5px 5px #080808;
                transform: scale(1.01);
            }

            &:active {
                border: 1px solid #151515;
                box-shadow: 1px 1px 1px #050505;
                transform: scale(0.99);
            }
        }
    }
}