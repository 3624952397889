* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  /* overflow-x: hidden; */
}

:root {
  scroll-behavior: smooth;
  -o-scroll-behavior: smooth;
  -ms-scroll-behavior: smooth;
  -webkit-scroll-behavior: smooth;
  -moz-scroll-behavior: smooth;
  font-size: 62.5%;
  /* overflow: hidden; */
  width: 100%;
}

body {
  background-color: #282827;
  color: #fff;
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.5rem;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  background-color: transparent;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  padding: 1.5rem 2rem;
  transition: all .5s;
}

a{
  color: #fff;
  text-decoration: none;
}