@import "../../scss/utilities/variables";
@import "../../scss/utilities/mixins";

.container {
    @include flexbox;
    gap: 3rem;
    padding: 1rem 1.5rem;
    width: 100%;

    img {
        width: 5rem;
    }

    h1 {
        font-size: 4rem;
        text-align: center;
    }
}