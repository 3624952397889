@import "../../scss/utilities/variables";
@import "../../scss/utilities/mixins";
@import "../../scss/utilities/keyframes";

.container {
    @include box-decoration;
    background-color: $night-grey;
    background: $gradient-6;
    border-radius: 10px;
    font-size: 1.7rem;
    margin: 4rem auto 2rem;
    max-width: 70rem;
    padding: 2rem 3rem 5rem;
    width: 100%;
}

.title {
    @include h-center;
    animation: moveGradient 4s ease alternate infinite;
    background: $gradient-4;
    background-size: 400%;
    background-position: 0 50%;
    border-radius: 5px;
    color: $white;
    max-width: 40rem;
    padding: 1px;
    text-align: center;
    transform: translateY(-4.5rem);
    width: 80%;

    h2 {
        background-color: $night-grey;
        border-radius: 5px;
        padding: 1rem;
    }
}

.grid {
    @include flexbox($flow: row wrap, $j: space-evenly);
    gap: 4rem 2rem;

    &Item {
        @include flexbox($j:space-evenly);
        gap: .5rem;
        height: 10rem;
        width: 10rem;
    }

    h3 {
        font-weight: $light-font-weight;
        margin-top: auto;
    }
}