@import "../../scss/components/container";
@import "../../scss/utilities/variables";
@import "../../scss/utilities/mixins";


.container{
    margin: 3rem auto
}
.gridsContainer {
    @include flexbox;
    gap: 2rem;
    margin: 4rem auto 0;
    min-height: 100vh;
    overflow: hidden;
    width: 90%;
}